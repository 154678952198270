import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const dateFormat = 'yyyy-MM-dd'

export const formatPrice = (price, currency = 'EUR', locale = 'nl') => {
    if (currency) {
        return (Math.round(price) / 100).toLocaleString(locale, {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
        })
    } else {
        return (Math.round(price) / 100).toFixed(2).toLocaleString(locale)
    }
}

// returns discounted price if active
export const useDiscountPrice = (productLocation) => {
    if (productLocation && productLocation.is_discount_price) {
        return productLocation.price
    }
    if (productLocation && productLocation.discount_price) {
        // if only discount price is set
        if (
            !productLocation.discount_period_start &&
            !productLocation.discount_period_end
        ) {
            return productLocation.discount_price
        }
        const now = moment()
        const start = moment(productLocation.discount_period_start)
        const end = moment(productLocation.discount_period_end)

        // if only start is defined
        if (!productLocation.discount_period_end && start.isBefore(now)) {
            return productLocation.discount_price
        }

        // if only start is defined
        if (start.isBefore(now) && end.isAfter(now)) {
            return productLocation.discount_price
        }
    }
    return null
}

export const useDiscountField = ({
    discountPrice,
    retailPrice,
    start,
    end,
}) => {
    const [active, setActive] = useState(false)
    const [inputMethod, setInputMethod] = useState('price')
    const [booted, setBooted] = useState(false)

    // set active if needed
    useEffect(() => {
        if (active) return
        if (!discountPrice.value) return
        setActive(true)
    }, [active, discountPrice.value])

    // startup (do this only once)
    useEffect(() => {
        if (active) return
        if (!discountPrice.value) return
        if (booted) return

        const percentage = 100 - (discountPrice.value / retailPrice.value) * 100
        const amount = retailPrice.value - discountPrice.value

        setBooted(true)

        if (percentage % 1 === 0) {
            return setInputMethod('percentage')
        }
        if (percentage % 2.5 === 0) {
            return setInputMethod('percentage')
        }
        if (amount % 100 === 0) {
            return setInputMethod('amount')
        }
    }, [active, booted, discountPrice.value, retailPrice.value])

    const onCancel = useCallback(() => {
        discountPrice.setValue(null)
        start.setValue(null)
        end.setValue(null)
        setActive(false)
    }, [discountPrice, end, start])

    return useMemo(
        () => ({
            active,
            setActive,
            inputMethod,
            setInputMethod,
            onCancel,
        }),
        [active, inputMethod, onCancel]
    )
}
