import { Location } from '@zupr/types/fo'
import React, { useCallback, useContext } from 'react'

import RouteContext from '../../../context/route'

import Trans from '../../components/trans'
import ExternalLink from '../../router/link-external'

import Tooltip from '../../components/tooltip'

import { ReactComponent as LinkIcon } from '../../../../svg/globe.svg'

import '../../../../scss/react/components/action-links.scss'

interface WebsiteLinkProps {
    location: Location
    className?: string
    children?: React.ReactNode
    onClick?: () => void
    iconOnly?: boolean
}

const WebsiteLink = ({
    location,
    className,
    children,
    onClick,
    iconOnly,
}: WebsiteLinkProps) => {
    const { trackEvent } = useContext(RouteContext)

    const handleClick = useCallback(() => {
        // track event
        trackEvent({
            id: location.id,
            action: 'clickWebsite',
        })

        if (onClick) onClick()
    }, [location.id, onClick, trackEvent])

    return (
        <ExternalLink
            className={className}
            href={location.website}
            onClick={handleClick}
            aria-label="Visit website"
        >
            <span className="btn-social social-platform">
                <LinkIcon />
            </span>
            {!iconOnly && (children || <Trans label="Visit website" />)}
        </ExternalLink>
    )
}

export const WebsiteAction = ({ ...props }: WebsiteLinkProps) => (
    <Tooltip label="Visit website">
        <WebsiteLink className="btn btn-round" iconOnly {...props} />
    </Tooltip>
)

export default WebsiteLink
